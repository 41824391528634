import React from 'react';
import logo from '../../assets/images/logo/lim_logo_black.png';
import { Link } from 'react-router-dom';

function Success() {
    return (
        <div className="flex justify-center bg-transparent min-h-[100vh] xl:py-[81px] py-[90px]">
            <div className="flex flex-col items-center xl:gap-[276px] gap-[211px]">
                <img
                    src={logo}
                    alt="Lost In Melo"
                    className="xl:w-auto w-[232px]"
                />

                <Link to={"/"} type="submit"
                    className="flex justify-center items-center py-2.5 xl:mt-[15px] mt-2.5 xl:h-[60px] xl:w-[406px] w-full outline-none bg-gentlegreen rounded-[10px] text-white text-[32px] text-center font-bold">BIENVENUE
                    DANS <br className="xl:hidden" /> LE MOUVEMENT</Link>
            </div>
        </div>
    )
}

export default Success