import './home.css';
import React, { useState } from 'react';
import lim from '../../assets/images/logo/lim_logo_black.png';
import limwhite from '../../assets/images/logo/lim_logo_white.svg';
import lim_est_2024 from '../../assets/images/logo/lim_est_2024.svg';
import tshirt from '../../assets/images/merch/merchblackt.jpg';
import Newsletter from '../Newsletter/Newsletter';
import RightSidenav from '../Sidenav/Sidenav';

function Home() {

    const [isSidenavOpen, setIsSidenavOpen] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const [selectedCoverUrl, setSelectedCoverUrl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);

    function toggleSidenav(sessionId, coverUrl, date, month) {
        setSelectedSession(sessionId);
        setSelectedCoverUrl(coverUrl);
        setSelectedDate(date);
        setSelectedMonth(month);
        setIsSidenavOpen(!isSidenavOpen);
    }

    function handleReservation(session, coverUrl, date, month) {
        // Mise à jour de la session et ouverture du side-nav
        toggleSidenav(session, coverUrl, date, month);
        console.log(date + '+' + month)
    }

    const dayParties = [
        {
            session: "SESSION 01",
            date: "15",
            month: "SEPTEMBRE",
            startTime: "12",
            endTime: "18",
            coverUrl: "https://firebasestorage.googleapis.com/v0/b/lost-in-melo.appspot.com/o/SS01-SQ.jpg?alt=media&token=e680f666-1d2c-45e3-a842-698efa68c157",
            status: 2
        },
        {
            session: "SESSION 02",
            date: "22",
            month: "SEPTEMBRE",
            startTime: "14",
            endTime: "20",
            coverUrl: "https://firebasestorage.googleapis.com/v0/b/lost-in-melo.appspot.com/o/melodayparty-ss02-square.jpg?alt=media&token=09705557-5352-4077-b81e-42c466299fa0",
            status: 2
        },
        {
            session: "SESSION 03",
            date: "29",
            month: "SEPTEMBRE",
            startTime: "14",
            endTime: "20",
            coverUrl: "https://firebasestorage.googleapis.com/v0/b/lost-in-melo.appspot.com/o/melodayparty-ss02-square.jpg?alt=media&token=09705557-5352-4077-b81e-42c466299fa0",
            status: 2
        },
        {
            session: "SESSION 04",
            date: "06",
            month: "OCTOBRE",
            startTime: "14",
            endTime: "20",
            coverUrl: "",
            status: 1
        },

    ]

    return (
        <body className="flex flex-col items-center justify-center bg-transparent">

            <div className="flex flex-col items-center justify-center lg:px-[30px] lg:py-5 lg:m-0 p-5 w-full">

                <div className="lg:grid lg:grid-cols-2 grid-cols-1 rounded-[20px] w-full">

                    <div
                        className="flex items-center justify-center bg-black border-black border-2 w-full h-full lg:rounded-l-[20px] lg:rounded-tr-none rounded-t-[20px] lg:px-32 lg:py-20 py-[100px]">
                        <img className="max-w-[250px] lg:max-w-[500px]" src={limwhite} alt="" />
                    </div>

                    <div
                        className="hidden flex-col items-center bg-transparent border-black border-2 w-full h-full lg:rounded-r-[20px] lg:rounded-b-none rounded-b-[20px] lg:px-8 px-[30px] lg:py-[50px] py-[30px] gap-10">

                        <p className="text-black font-extrabold lg:text-[80px] text-[40px]">DAY PARTIES</p>

                        <div className="flex flex-col w-full lg:gap-5 gap-[15px] lg:pb-0 pb-10" id="dayparties">

                            {dayParties.map((session, index) => (

                                <div key={index} className="flex justify-between border-t-2 border-black pt-5">

                                    <div className="flex flex-col gap-1 lg:w-4/12 text-black">
                                        <p className="font-bold lg:text-[32px] text-[20px]">MELO DAY PARTY</p>
                                        <p className="font-light lg:text-[24px] text-[14px]">{session.session} • {session.startTime}H-{session.endTime}H</p>
                                        <a href="https://maps.app.goo.gl/ek6fiGLpephXAG786" className="location" target="_blank"
                                            rel="noopener noreferrer">
                                            <p className="font-light lg:text-[20px] text-[12px]">La Villa****</p>
                                        </a>

                                        <div className="flex lg:hidden gap-2 h-[36px] text-black">
                                            <p className="flex items-center justify-center font-bold lg:text-[30px] text-[15px]">{session.date}</p>
                                            <p
                                                className="w-[90px] flex items-center justify-center font-light lg:text-[20px] text-[12px] p-2.5 border-2 border-black rounded-[5px]">
                                                {session.month} 2024</p>
                                        </div>
                                    </div>

                                    <div className="lg:flex hidden items-start justify-start lg:w-4/12">
                                        <div className="flex gap-2 h-[36px] text-black">
                                            <p className="font-bold text-[30px]">{session.date}</p>
                                            <p
                                                className="w-[150px] flex items-center justify-center font-light text-[20px] p-2.5 border-2 border-black rounded-[5px]">
                                                {session.month} 2024</p>
                                        </div>
                                    </div>

                                    <div className="flex lg:items-start items-end justify-end lg:w-4/12">
                                        {
                                            session.status === 2 ? (
                                                <button
                                                    id="openModal"
                                                    className="text-black bg-transparent lg:text-[20px] text-[18px] font-bold h-[36px] p-2.5 rounded-full reserve lg:w-auto w-fit cursor-not-allowed"
                                                    disabled
                                                >
                                                    OUTDATED
                                                </button>
                                            ) : session.status === 1 ? (
                                                <button
                                                    id="openModal"
                                                    onClick={() => handleReservation(session.session, session.coverUrl, session.date, session.month)}
                                                    className="text-white bg-black lg:text-[24px] text-[18px] font-bold lg:px-[30px] lg:py-2.5 px-[25px] py-[5px] rounded-full reserve"
                                                >
                                                    RESERVE
                                                </button>
                                            ) : session.status === 0 && (
                                                <button
                                                    id="openModal"
                                                    className="text-black bg-transparent lg:text-[20px] text-[18px] font-bold h-[36px] p-2.5 rounded-full reserve lg:w-auto w-fit cursor-not-allowed"
                                                    disabled
                                                >
                                                    NOT AVAILABLE
                                                </button>
                                            )
                                        }
                                    </div>


                                </div>

                            ))}

                        </div>

                    </div>

                    <div
                        className="flex flex-col items-center justify-center bg-transparent border-black border-2 w-full h-full lg:rounded-r-[20px] lg:rounded-b-none rounded-b-[20px] lg:px-8 px-[30px] lg:py-[180px] py-[70px] gap-10">

                        <p className="text-black text-center font-extrabold lg:text-[80px] text-[40px]">NEXT DATES WILL BE ANNOUNCED SOON.</p>

                    </div>

                </div>

                <Newsletter />

                <div
                    className="flex lg:flex-row flex-col justify-between lg:items-start items-center lg:gap-0 gap-[30px] lg:mt-[80px] mt-[50px] lg:max-w-[1024px] w-full">
                    <p className="text-black lg:text-[52px] text-[32px] font-light lg:text-left text-center">MERCHANDISING OFFICIEL <br />
                        COMING SOON.</p>
                    <img className="lg:w-auto w-[180px]" src={lim} alt="" />
                </div>

                <div className="hidden lg:grid-cols-4 grid-cols-2 mt-10 lg:gap-[30px] gap-5 lg:max-w-[1024px] w-full ">
                    <div className="h-[300px] rounded-[5px] flex justify-center items-center">
                        <img src={tshirt} alt="" />
                    </div>
                    <div className="h-[300px] bg-coolbreeze rounded-[5px]"></div>
                    <div className="h-[300px] bg-coolbreeze rounded-[5px]"></div>
                    <div className="h-[300px] bg-coolbreeze rounded-[5px]"></div>
                </div>

            </div>

            <footer className="flex justify-center w-full border-t-2 border-black mt-[70px]">

                <div
                    className="flex flex-col justify-center items-center gap-[50px] lg:mt-[50px] mt-5 lg:mb-10 mb-[50px] lg:max-w-[1024px] w-full ">

                    <img src={lim_est_2024} alt="" />

                    <div className="flex justify-center items-center w-full mb-[10]">
                        <a className="text-black font-light lg:text-[72px] text-[34px] text-center"
                            href="https://www.instagram.com/lostinmelo/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
                    </div>

                    <p className="lg:text-[24px] text-[14px] font-light text-black lg:-rotate-90 lg:absolute lg:right-0 lg:mb-10">© 2024
                        LOST IN MELO -
                        LÉGAL</p>

                </div>

            </footer>

            <RightSidenav
                isOpen={isSidenavOpen}
                closeSidenav={() => setIsSidenavOpen(false)}
                session={selectedSession}
                coverUrl={selectedCoverUrl}
                date={selectedDate}
                month={selectedMonth}
                toggleSidenav={toggleSidenav}
            />

        </body>

    )
}

export default Home